import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Container, Row, Col } from 'react-bootstrap';

import Background from './Backgorund';
import List, { ListType } from './List';
import Price, { PriceType } from './Price';

import styles from './Plan.module.scss';

interface PricingPlanProps {
  id: string;
  listData: ListType;
  price: PriceType;
  logo: string;
  international?: string;
}

const PricingPlan: React.FC<PricingPlanProps> = ({
  id, listData, price, logo, international,
}) => (
  <Container className={styles.container}>
    <Background id={id}>
      <div className={styles.planContainer}>
        <Row>
          <Col sm="12" md="7" lg="7" xl="7">
            <div className={styles.list}>
              <img className={styles.logo} src={logo} alt="teams logo" />
              <List data={listData} />
            </div>
            {international ? (
              <p className={styles.international}>
                <FormattedMessage id={`${international}`} />
              </p>
            ) : (null)}
          </Col>
          <Col sm="12" md="5" lg="5" xl="5">
            <Price data={price} />
          </Col>
        </Row>
      </div>
    </Background>
  </Container>
);

export default PricingPlan;
