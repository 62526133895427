import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useStaticQuery, graphql } from 'gatsby';
import GatsbyImage from 'gatsby-image';
import { FormattedMessage } from 'react-intl';
import { isMobileOS } from 'helpers/utils/mobile';
import GeneralSettings from 'helpers/constants/settings';

import cmnStyles from 'styles/pages.module.scss';
import styles from './Intro.module.scss';

const PricingIntro: React.FC = () => {
  const [isMobile, setMobile] = useState(false);
  const colTitle = `${cmnStyles.alignSectionVerticallyCenter} ${styles.colTitle}`;

  const updateWidth = (): void => {
    setMobile(isMobileOS() || window.innerWidth < GeneralSettings.MOBILE_VIEW.PRICING_INTRO);
  };

  useEffect(() => {
    setMobile(isMobileOS());
    updateWidth();
    window.addEventListener('resize', updateWidth);
    return (): void => window.removeEventListener('resize', updateWidth);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    imageIntro,
    imageIntroMobile,
  } = useStaticQuery(
    graphql`
        query {
            imageIntro: file(relativePath: { eq: "pricing/intro.png" }) {
                childImageSharp {
                    fluid(maxWidth: 845) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64,
                    }
                }
            },
            imageIntroMobile: file(relativePath: { eq: "pricing/intro-mobile.png" }) {
              childImageSharp {
                  fluid(maxWidth: 750) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64,
                  }
              }
          },
        }
    `,
  );

  const introPicture = isMobile
    ? <GatsbyImage fluid={imageIntroMobile.childImageSharp.fluid} />
    : <GatsbyImage fluid={imageIntro.childImageSharp.fluid} />;

  return (
    <Container className={styles.main}>
      <Row>
        <Col
          xs={{ span: 12, order: 2 }}
          sm={{ span: 12, order: 2 }}
          md={{ span: 12, order: 2 }}
          lg={{ span: 6, order: 1 }}
          xl={{ span: 5, order: 1 }}
          className={colTitle}
        >
          <h1 className={`${cmnStyles.articleTitle} ${styles.title}`}>
            <FormattedMessage id="components.pricing.intro" />
          </h1>
        </Col>
        <Col
          xs={{ span: 12, order: 1 }}
          sm={{ span: 12, order: 1 }}
          md={{ span: 12, order: 1 }}
          lg={{ span: 6, order: 1 }}
          xl={{ span: 6, order: 1 }}
        >
          {introPicture}
        </Col>
      </Row>
    </Container>
  );
};

export default PricingIntro;
