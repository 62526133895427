import React from 'react';
import { Container } from 'react-bootstrap';

import teamsLogo from 'images/pricing/teams.svg';
import logoSmall from 'images/logos/logo-small.svg';

import PricingPlan from '../Plan';

const root = 'components.pricing.plans';
const mobileNumberOnoff = `${root}.mobileNumberOnoff`;
const mobileNumberTeams = `${root}.mobileNumberTeams`;

export enum TextSizes {
  Normal = 'normal',
  Smaller = 'smaller'
}

const PlanMobileNumberOnoff = {
  id: 'mobileNumberOnoff',
  title: `${mobileNumberOnoff}.title`,
  titleSize: TextSizes.Smaller,
  list: [ // list of constants to translate
    `${mobileNumberOnoff}.item1`,
    `${mobileNumberOnoff}.item2`,
    `${mobileNumberOnoff}.item3`,
  ],
  price: {
    title: '9,00€',
    titleSize: TextSizes.Normal,
    p1: `${mobileNumberOnoff}.right1`,
  },
  international: `${mobileNumberOnoff}.international`,
};
const PlanMobileNumberTeams = {
  id: 'mobileNumberTeams',
  title: `${mobileNumberTeams}.title`,
  titleSize: TextSizes.Smaller,
  list: [
    `${mobileNumberTeams}.item1`,
    `${mobileNumberTeams}.item2`,
    `${mobileNumberTeams}.item3`,
  ],
  price: {
    title: '12,00€',
    titleSize: TextSizes.Normal,
    p1: `${mobileNumberOnoff}.right1`,
  },
};

const PricingPlans: React.FC = () => (
  <Container>
    <PricingPlan
      id={PlanMobileNumberOnoff.id}
      listData={{
        title: PlanMobileNumberOnoff.title,
        titleSize: PlanMobileNumberOnoff.titleSize,
        list: PlanMobileNumberOnoff.list,
      }}
      logo={logoSmall}
      price={PlanMobileNumberOnoff.price}
      international={PlanMobileNumberOnoff.international}
    />
    <PricingPlan
      id={PlanMobileNumberTeams.id}
      listData={{
        title: PlanMobileNumberTeams.title,
        titleSize: PlanMobileNumberTeams.titleSize,
        list: PlanMobileNumberTeams.list,
      }}
      logo={teamsLogo}
      price={PlanMobileNumberTeams.price}
    />
  </Container>
);

export default PricingPlans;
