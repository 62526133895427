import React from 'react';
import Index, { IndexProps } from 'components/layout';
import SEO from 'components/seo';
import Menu from 'helpers/constants/menuConstants.json';
import PageWrapper from 'components/PageWrapper';
import PricingIntro from 'components/Pricing/Intro';
import PricingPlans from 'components/Pricing/Plans';
import { FlattenJson } from 'helpers/utils/url';
import { flatten } from 'flat';
import BannerTransform from 'components/Banners/BannerTransform';
import BannerInnerLink, { Pages } from 'components/Banners/BannerInnerLink';

const menuFlatten: FlattenJson = flatten(Menu);
const PricingPage: React.FC<IndexProps> = ({ pageContext }) => (
  <Index pageContext={pageContext}>
    <SEO pageTitleTermPath="pricing" />
    <PageWrapper withBottomBackground>
      <PricingIntro />
      <PricingPlans />
    </PageWrapper>
    <BannerInnerLink
      page={Pages.Pricing}
      urlToPage={menuFlatten[`HEADER.PRICING.URL.${pageContext.language}`]}
    />
    <BannerTransform />
  </Index>
);

export default PricingPage;
