import React from 'react';
import leftSm from 'images/pricing/international/leftsm.svg';
import leftBig from 'images/pricing/international/leftbig.svg';

import rightNet from 'images/pricing/international/netbottom.svg';
import rightNum from 'images/pricing/international/numbottom.svg';

import bgIntl from './index.module.scss';

const PricingBgIntl: React.FC = () => {
  const bgLeftImages = (
    <>
      <div className={bgIntl.bgLeftSm}>
        <img src={leftSm} alt="leftBallSmall" />
      </div>
      <div className={bgIntl.bgLeftBig}>
        <img src={leftBig} alt="leftBallBig" />
      </div>
    </>
  );
  const bgRightImages = (
    <>
      <div className={bgIntl.rightNum}>
        <img src={rightNum} alt="rightNum" />
      </div>
      <div className={bgIntl.rightNet}>
        <img src={rightNet} alt="rightNet" />
      </div>
    </>
  );

  return (
    <>
      {bgLeftImages}
      {bgRightImages}
    </>
  );
};

export default PricingBgIntl;
